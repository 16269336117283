import * as React from "react";
import Emoji, { EmojiNames } from "./emoji";

interface EmojiButtonProps {
  text: string;
  emoji: EmojiNames;
}

const emojiButton = ({ text, emoji }: EmojiButtonProps) => (
  <button className="transition ease-in-out pl-4 pr-5 h-9 flex items-center gap-[10px] font-nexa-bold text-sm rounded-sm border-text-tertiary border-[0.5px] bg-gradient-to-b from-transparent hover:from-[#f0f9fe] to-transparent hover:border-text-blue">
    <Emoji emoji={emoji} size={18} />
    <span>{text}</span>
  </button>
);

export default emojiButton;
